import './styles.css'
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer';
import Title from '../../components/Title';

export default function Search() {
    return (
        <section className='search-container'>
            <Header />
            <Hero title={'Laboratório de Materiais'} subTitle={'Ambiente Multiusuário | PNIPE-MCTI'} />
            
            <section className='content-container'>
                <Title content={'Pesquisas Relacionadas'} />

                <p>O Laboratório de Materiais do IFPB Campus Cajazeiras é um espaço dinâmico e em expansão, com foco na caracterização de materiais metálicos, compósitos e nanoestruturados. Equipado com instrumentos de alta precisão, o laboratório apoia pesquisas acadêmicas e aplicadas, oferecendo suporte técnico e científico para diversas áreas estratégicas. Atualmente, o laboratório está estruturado para atender à crescente demanda por análises detalhadas, com equipamentos especializados para preparação de amostras, testes mecânicos e inspeções ópticas. No entanto, o potencial do laboratório será significativamente ampliado com o planejamento para a aquisição de um Microscópio Eletrônico de Varredura (MEV) e de um sistema óptico Alicona FocusX. Esses novos instrumentos trarão capacidade de caracterização avançada e análise tridimensional, promovendo sinergias entre técnicas ópticas e análises de microestruturas e tensões residuais, posicionando o laboratório como um centro de excelência em pesquisa e inovação no Nordeste.</p>

                <div>
                    
                    <div className='pesquisar-container'>
                        <h3 >EFEITOS DOS PARÂMETROS DE CORTE NO COMPRIMENTO DE CONTATO BASEADO NO CRITÉRIO DE POLETICA PARA CORTE ORTOGONAL DO A7075-T645</h3>

                        <p>SILVA NETO, A. M. ; GUEDES FILHO, F. X. ; DA SILVA, F.A.V. ; ROMUALDO FIGUEIREDO DE SOUSA ; LINHARES, L. L. S. ; SILVA, L. P. L. ; ANDRADE, J. S. . EFEITOS DOS PARÂMETROS DE CORTE NO COMPRIMENTO DE CONTATO BASEADO NO CRITÉRIO DE POLETICA PARA CORTE ORTOGONAL DO A7075-T645. In: 75° Congresso Anual da ABM, 2022, São Paulo. FUNDAMENTOS E PROCESSOS METALÚRGICOS, 2022. v. 75. p. 3682-3694.
                        </p>

                    </div>

                    <div className='pesquisar-container'>
                        <h3>OTIMIZAÇÃO DO PROCESSO DE FURAÇÃO EM LIGA DE TITÂNIO G2 UTILIZANDO PLANEJAMENTO EXPERIMENTAL FATORIAL</h3>
                        <p>ARRUDA, Rabeche Keise da Silva; ANDRADE, Joab; SOUZA, Taciana; SILVA, Francisco Augusto Vieira da; SILVA, Edleusom Saraiva da; SILVA, Leonardo Pereira de Lucena. Otimização do processo de furação em liga de titânio G2 utilizando planejamento experimental fatorial. Anais do Congresso Nacional de Engenharia Mecânica (CONEM 2024), DOI: 10.26678/ABCM.CONEM2024.CON24-0312. </p>
                    </div>

                    <div className='pesquisar-container'>
                        <h3>AVALIAÇÃO DO COMPORTAMENTO ESTRUTURAL E MICROESTRUTURAL DO AÇO ASTM A-36 UTILIZANDO O MÉTODO DE PRÉ-AQUECIMENTO EM SOLDAGEM GMAW E SMAW</h3>
                        <p>QUEIROZ, Paulo Ricardo de Oliveira; QUEIROZ, Luiz Paulo de Oliveira; FAÇANHA JÚNIOR, Rairton Helder; SOUSA, Maria Larissa de. Avaliação do comportamento estrutural e microestrutural do aço ASTM A-36 utilizando o método de pré-aquecimento em soldagem GMAW e SMAW. Anais do Congresso Nacional de Engenharia Mecânica (CONEM 2024), Natal, RN, Brasil, 2024. DOI: 10.26678/ABCM.CONEM2024.CON24-1369. </p>
                    </div>

                </div>
            </section>

            <Footer />
        </section>
    );
}