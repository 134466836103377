import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//telas
import Presentation from "../pages/Presentation";
import Documents from "../pages/Documents";
import Search from "../pages/Search";
import StructureAndMachines from "../pages/StructureAndMachine";
import Contact from "../pages/Contact";

//rotas para paginação entre telas
export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/search" element={<Search />}/>
        <Route path="/structure-and-machines" element={<StructureAndMachines />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/" element={<Presentation />} />
        <Route path="/documents-uteis" element={<Documents />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </Router>
  );
}
