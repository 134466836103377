import DocumentsContent from "./Content";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";
import './styles.css';

export default function Documents() {
    return (
        <section className='documents-container'>
            <Header />
            <Hero title={'Laboratório de Materiais'} subTitle={'Ambiente Multiusuário | PNIPE-MCTI'} />
            <DocumentsContent />
            <Footer />
        </section>
    );
}