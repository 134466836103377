import Title from '../../../components/Title';
import '../../../assets/styles/content.css';
import './styles.css';

// Conteúdo exibido na página de contato
export default function ContactContent() {
    return (
        <section className="content-container">
            <Title content="Contato" />
            <div className="contact-container">
                <div className="content-info">
                    <span className="label">Coordenador:</span>
                    <p>Prof. Dr. Francisco Augusto Vieira da Silva</p>
                </div>
                <div className="content-info">
                    <span className="label">Lattes:</span>
                    <a href="http://lattes.cnpq.br/9853021802834621" target="_blank" rel="noopener noreferrer">
                        http://lattes.cnpq.br/9853021802834621
                    </a>
                </div>
                <div className="content-info">
                    <span className="label">Telefone:</span>
                    <p>(83) 98211-0910</p>
                </div>
                <div className="content-info">
                    <span className="label">Email:</span>
                    <p>lm.cz@ifpb.edu.br</p>
                </div>
            </div>

            <div className='list-docs-container'>
                <h2>Comitês</h2>

                <p className='about-comite' >Contamos com um Comitê Gestor e um Comitê de Usuários, que supervisionam e avaliam as práticas de utilização, promovendo um ambiente colaborativo e sustentável. Acesse os regulamentos e informações completas por meio de nossos links institucionais.</p>

                <ul>
                    <li>
                        <span>Comitê de usuários:</span> 
                        <a download={"Comitê de Usuários.pdf"} href="/docs/comite/Comitê de Usuários.   pdf">comitê de usuários
                        </a>
                    </li>
                    <li>
                        <span>Comitê gestor:</span> <a download={"Comitê Gestor.pdf"} href="docs/comite/Comitê Gestor.pdf">comitê gestor
                        </a>
                    </li>
                </ul>
            
            </div>

            <h2>Endereço</h2>
            <p>O laboratório está localizado no campus da instituição, com infraestrutura dedicada à pesquisa, ensino e inovação na área de materiais.</p>
            <ul className="address-list">
                <li><span className="label">Rua:</span> José Antônio da Silva, 300</li>
                <li><span className="label">Bairro:</span> Jardim Oásis</li>
                <li><span className="label">Cidade:</span> Cajazeiras - PB</li>
                <li><span className="label">CEP:</span> 58.900-000</li>
            </ul>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d126766.92628366634!2d-38.47040008973882!3d-6.834542799629972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sifpb%20google%20maps!5e0!3m2!1spt-BR!2sbr!4v1733530963818!5m2!1spt-BR!2sbr"
                width="550"
                height="350"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Localização IFPB"
            />

            <div className='agendamento-container'>
                <h2>Agendamento</h2>

                <div>
                    <p>Observações</p>
                    <ul className='observacoes-container'>
                        <li>Você só pode realizar um agendamento por dia;</li>
                        <li>Uma cópia do agendamento será enviada no seu email</li>
                        <li>Você deverá enviar uma mensagem (<a rel='noreferrer' target='_blank' href="https://drive.google.com/file/d/13WaD8looU6S-pVbW24hRtBRMGf1EAWqN/view?usp=drive_link">atrvés do Google agenda, na parte que consta seu agendamento</a>) informado se é necessário ter acompanhamento técnico e/ou precisará de equipamentos, se sim, quais;</li>
                        <li>Sempre fique atento a possíveis retornos;</li>
                    </ul>
                </div>

                <iframe title="agendamento lab de materiais" src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3PP5b9A8UWNDgSfY46_gVru6eJ5EKBibn-Vw6ksTEILUo43SN-kbE37p0dkzx0Dt4iZ_aLCQq3" width="90%" height="500px" frameborder="0" scrolling="yes"></iframe>
            </div>
        </section>
    );
}