import './styles.css'

//visando a flexibilidade, o título, subtítulo e path da imagem são passados como propriedades para o componente
export default function Hero({title, subTitle}) {
    return (
        <section className='hero-container'>
            <div className='hero-div-titles'>
                <h1>{title}</h1>
                <p>{subTitle}</p>
            </div>
        </section>
    );
}