import './styles.css'

import React, { useEffect, useState } from 'react';

const ImageSlider = ({ images }) => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 8000);

    return () => clearTimeout(timer);
  },); 

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='slide-container'>
      <div className='image-exibition'>
        <img src={images[currentIndex]} alt={`slide-${currentIndex + 1}`} />
      </div>

      <div className='button-container'>
          <button id='next-button' className='button-control' onClick={prevSlide}> ❮ </button>
          <button id='previus-button' className='button-control' onClick={nextSlide}> ❯ </button>
      </div>

    </div>
  );
};

export default ImageSlider;
