import Navigation from '../Navigation';
import './styles.css'

export default function Header() {
    return (
        <header className='header-container'>

            <img src="/img/ifpb_logo.png" alt="logo do IFPB" />

            <Navigation/>

            <img src="/img/lab_mat_logo.png" alt="logo do laboratorio de materiais" />

        </header>
    );
}