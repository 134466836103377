import './styles.css'

export default function StructureCard({ imgPath, name, code, marc, model }) {
    return (
        <div className='structure-card-container'>
            <img src={imgPath} alt="imagem" />

            <div className='espe-container'>
                <div>
                    <span className='name-item' >{name}</span>
                </div>

                <div>
                    <span>Código:</span>
                    <p>{code}</p>
                </div>

                <div>
                    <span>Marca:</span>
                    <p>{marc}</p>
                </div>

                <div>
                    <span>Modelo:</span>
                    <p>{model}</p>
                </div>

            </div>
        </div>
    );
}