import './styles.css'
import '../../assets/styles/content.css'
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer';
import Title from '../../components/Title'
import StructureCard from '../../components/StructureCard';

export default function StructureAndMachines() {
    return (
        <section className='structure-and-machines-container'>
            <Header />
            <Hero title={'Laboratório de Materiais'} subTitle={'Ambiente Multiusuário | PNIPE-MCTI'} />

            <div className='content-container'>
                <Title content={'Estrutura e Equipamentos'} />

                <p>Nesta página, você encontrará informações detalhadas sobre os equipamentos disponibilizados pelo laboratório, incluindo suas especificações.</p>

                <div className='card-structure-container'>
                    <StructureCard imgPath={'/img/capela-de-exaustao.jpg'} code={"CodVHT081/22"} marc={"Lucadema"} model={"à inserir"} name={"Capela de exaustão"}/>

                    <StructureCard imgPath={'/img/cortadora-metalografica.jpg'} code={"CodNQ86L6/22"} marc={"Teclago"} model={"CM80"} name={"Cortadora Metalográfica"}/>

                    <StructureCard imgPath={'/img/durometro.jpg'} code={"Cod41X1EQ/22"} marc={"TIME"} model={"TH301"} name={"Durômetro Rockwell"}/>

                    <StructureCard imgPath={'/img/embutidora-digital.jpg'} code={"CodXIK197/22"} marc={"FORTEL"} model={"EFD40"} name={"Embutidora Digital"}/>

                    <StructureCard imgPath={'/img/forno-mufla.jpeg'} code={"Cod4U2E1X/22"} marc={"Jung"} model={"J200"} name={"Forno Mufla"}/>

                    <StructureCard imgPath={'/img/lixadeira.jpg'} code={"CodIY77F2/22"} marc={"FORTEL"} model={"PLF"} name={"Lixadeira e Politriz"}/>

                    <StructureCard imgPath={'/img/microscopio-trinocular.jpg'} code={"CodD8R2C6/22"} marc={"Physis"} model={"à inserir"} name={"Microscópio Estereoscópico"}/>

                    <StructureCard imgPath={'/img/microscopio.jpg'} code={"Cod973LOS/22"} marc={"Kontrol"} model={"R"} name={"Microscópio trinocular"}/>

                </div>
            </div>

            <Footer/>
        </section>
    );
}