import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import ContactContent from './Content';
import './styles.css'

//página de contato do site
export default function Contact() {
    return (
        <section className='contact-container'>
            <Header />
            <Hero title={'Laboratório de Materiais'} subTitle={'Ambiente Multiusuário | PNIPE-MCTI'} />
            <ContactContent />
            <Footer />
        </section>
    );
}