import '../../../assets/styles/content.css';
import './styles.css'
import Title from '../../../components/Title';

export default function DocumentsContent() {
    return (
        <section className='content-container' >
            <Title content={'Documentos Úteis'} />
            <p>A governança documental do Laboratório de Materiais é estruturada para garantir eficiência, transparência e segurança. Entre os documentos disponíveis, destacam-se:
            </p>
            <h2>Documentos</h2>

            <div className='list-docs-container docs-container'>
                    <ul>
                        <li>
                            <span>Manual de Segurança:</span> 

                            <div>
                            <p>Protocolo detalhado para o uso seguro da infraestrutura e manuseio de equipamentos-</p>
                                <a download={"Manual de Segurança.pdf"} href="/docs/docs_uteis/Manual de Segurança.pdf">manual de segurança
                                </a>
                            </div>
                        </li>

                        <li>
                            <span>Regras de Agendamento:</span> 

                            <div>
                                <p>Diretrizes claras para agendamento e controle de acesso, disponíveis via sistema online integrado ao SUAP e PNIPE-</p>
                                <a download={"Regras de Agendamento.pdf"} href="/docs/docs_uteis/Regras de Agendamento.pdf">regras de agendamento
                                </a>
                            </div>
                        </li>

                        <h3>Outros</h3>
                        
                        <li>
                            <span>Plano de Sutentabilidade:</span>
                            <a download={"Plano de Sustentabilidade.pdf"} href="/docs/docs_uteis/Plano de Sustentabilidade.pdf">plano de sustentabilidade
                            </a>
                        </li>

                        <li>
                            <span>Regulamento do Laboratório:</span>
                            <a download={"Regulamento do Laboratório.pdf"} href="/docs/docs_uteis/Regulamento dos Laboratórios.pdf">regulamento do laboratorio
                            </a>
                        </li>

                        <li>
                            <span>Aprovação das Normas de Organização Administrativa:</span>
                            <a download={"Aprovação das Normas de Organização Administrativa.pdf"} href="/docs/docs_uteis/Aprovação das Normas de Organização Administrativa.pdf">aprovação de normas
                            </a>
                        </li>
                    </ul>
            </div>
        </section>
    );
}