import './styles.css'

//conteúdo que será exibido no "Footer" da página, como informações do laboratório, contatos, etc
export default function FooterContent() {

    return (
        <div className='footer-content-container' >
            <p>Desenvolvido por <a href="https://loopisjr.com.br/" target='_blank' rel="noreferrer">Loopis Jr</a></p>
            <p className='copyright-content'>Copyright @2024. Todos os direitos reservados</p>
        </div>
    );
}