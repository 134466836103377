import "./styles.css"

export default function AreaCard({ title, content }) {
    return (
        <section className="area-card-container">
            <p>{title}</p>
            <div>
                {//content é um array com o conteudo do card
                    content.map(item => 
                        <div key={item}>
                            <p className="title-area-card">{item.title}</p>
                            <p className="content-area-card">{item.content}</p>
                        </div>
                    )
                }
            </div>
        </section>
    );
}